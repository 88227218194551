<template>
  <span>
    <v-dialog v-model="dialog" width="800" persistent eager>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="secondary"
          v-bind="attrs"
          v-on="on"
          @click="editProfile"
        >
          <v-icon>{{ icon.edit }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline primary white--text">
          Update User Profile
        </v-card-title>

        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="form.physical_activity"
                :items="physicalActivities"
                :error-messages="form.$getError('physical_activity')"
                :hide-details="!form.$getError('physical_activity')"
                label="Physical Activity"
                item-text="name"
                item-value="id"
                outlined
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="form.workout_frequency"
                :items="workoutFrequencies"
                :error-messages="form.$getError('workout_frequency')"
                :hide-details="!form.$getError('workout_frequency')"
                label="Workout Frequency"
                item-text="name"
                item-value="id"
                outlined
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="form.target_goal"
                :items="targetGoals"
                :error-messages="form.$getError('target_goal')"
                :hide-details="!form.$getError('target_goal')"
                label="Target Goal"
                item-text="name"
                item-value="id"
                outlined
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="form.restrictions"
                :items="restrictions"
                :error-messages="form.$getError('restrictions')"
                :hide-details="!form.$getError('restrictions')"
                label="Restrictions"
                item-text="name"
                item-value="id"
                multiple
                outlined
                small-chips
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-text class="pt-5">
          <v-row>
            <v-col>
              <v-text-field
                v-model="form.height"
                label="Height (cm)"
                :error-messages="form.$getError('height')"
                :hide-details="!form.$getError('height')"
                outlined
                required
                flat
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.weight"
                label="Weight (kg)"
                :error-messages="form.$getError('weight')"
                :hide-details="!form.$getError('weight')"
                outlined
                required
                flat
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.chest"
                label="Chest (cm)"
                :error-messages="form.$getError('chest')"
                :hide-details="!form.$getError('chest')"
                outlined
                required
                flat
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="form.waist"
                label="Waist (cm)"
                :error-messages="form.$getError('waist')"
                :hide-details="!form.$getError('waist')"
                outlined
                required
                flat
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.hip"
                label="Hips (cm)"
                :error-messages="form.$getError('hip')"
                :hide-details="!form.$getError('hip')"
                outlined
                required
                flat
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.arms"
                label="Arms (cm)"
                :error-messages="form.$getError('arms')"
                :hide-details="!form.$getError('arms')"
                outlined
                required
                flat
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.legs"
                label="Legs (cm)"
                :error-messages="form.$getError('legs')"
                :hide-details="!form.$getError('legs')"
                outlined
                required
                flat
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-5">
          <v-spacer />
          <v-btn color="secondary" text @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn color="primary" :loading="form.$busy" @click="submit" text>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-toast ref="toast" />
  </span>
</template>

<script>
import VToast from '@/components/elements/Toast'
import { mapActions, mapState } from 'vuex'
import { mdiTableEdit } from '@mdi/js'
import Form from '@/utils/form'
import Event from '@/services/eventBus'
import { pick } from 'lodash'

export default {
  name: 'EditUserProfileDialog',

  props: {
    profile: {
      type: Object,
      required: true,
    },
    updateProfileApi: {
      type: Function,
      required: true,
    },
  },

  components: {
    VToast,
  },

  data() {
    return {
      icon: {
        edit: mdiTableEdit,
      },
      dialog: false,

      form: new Form({
        id: null,
        email: null,
      }),
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.users.selectedUser,
      workoutFrequencies: (state) => state.users.extra.types.workoutFrequencies,
      physicalActivities: (state) => state.users.extra.types.physicalActivities,
      restrictions: (state) => state.users.extra.types.restrictions,
      targetGoals: (state) => state.users.extra.types.targetGoals,
    }),
  },

  methods: {
    ...mapActions({
      getSelectedUserDetails: 'users/getSelectedUser',
    }),

    editProfile() {
      const editableData = pick(this.profile, [
        'id',
        'physical_activity',
        'workout_frequency',
        'target_goal',
        'restrictions',
        'height',
        'weight',
        'chest',
        'waist',
        'hip',
        'arms',
        'legs',
      ])
      this.form = new Form(editableData)
      this.form.$busy = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      let payload = this.form.$data()
      payload.user_id = this.user.id

      this.updateProfileApi(payload)
        .then(async (res) => {
          this.form.$timeout(() => {
            this.user.email = this.form.email
            this.form.$busy = false
            this.dialog = false

            Event.$emit('reload-user-profile')

            this.$refs.toast.open({
              color: 'primary',
              message: 'User profile successfully updated.',
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
        })
    },
  },
}
</script>
