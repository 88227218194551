<template>
  <span>
    <v-dialog v-model="dialog" persistent width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="secondary" v-bind="attrs" v-on="on" @click="editAge">
          <v-icon>{{ icon.editIcon }}</v-icon>
        </v-btn>
      </template>

      <v-date-picker v-model="form.birthday" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="submit"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-toast ref="toast" />
  </span>
</template>

<script>
import VToast from '@/components/elements/Toast'
import { mapActions, mapState } from 'vuex'
import { mdiPencil } from '@mdi/js'
import Form from '@/utils/form'
import { first } from 'lodash'

export default {
  name: 'UserEditEmailDialog',

  props: {},

  components: {
    VToast,
  },

  data() {
    return {
      icon: {
        editIcon: mdiPencil,
      },
      dialog: false,

      form: new Form({
        id: null,
        email: null,
      }),
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.users.selectedUser,
    }),
  },

  methods: {
    ...mapActions({
      updateUser: 'users/updateUser',
    }),

    editAge() {
      this.form = new Form({ id: this.user.id, birthday: this.user.birthday })
      this.form.$busy = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateUser(this.form.$data())
        .then(({ data }) => {
          this.form.$timeout(() => {
            this.user.birthday = data.birthday
            this.user.age = data.age
            this.form.$busy = false
            this.dialog = false

            this.$refs.toast.open({
              color: 'primary',
              message: 'Age successfully updated.',
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (err.response && err.response.status === 422) {
              const message = first(err.response.data.errors.birthday)
              this.$refs.toast.open({
                color: 'red',
                message,
              })
            }
          })
        })
    },
  },
}
</script>
