<template>
  <div v-if="user" class="user-profile--information pt-5">
    <v-row>
      <v-col sm="12" class="d-flex">
        <v-avatar v-if="user" size="165px" color="red" tile>
          <img :src="user.avatarThumbUrl" alt="John" />
        </v-avatar>

        <div class="pl-10 d-flex align-start flex-wrap">
          <div class="user-profile--basic">
            <ul>
              <li class="grey--text">Email:</li>
              <li>
                {{ user.email }}
                <edit-email-dialog />
              </li>
            </ul>
          </div>

          <div class="user-profile--basic">
            <v-row>
              <v-col>
                <ul>
                  <li class="grey--text">Joined Date:</li>
                  <li>{{ user.joinedDate }}</li>
                </ul>
              </v-col>
              <v-col>
                <ul>
                  <li class="grey--text">Social media consent:</li>
                  <li v-if="user.main_profile">
                    {{ user.main_profile.share_photos_social ? 'Yes' : 'No' }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </div>

          <div class="user-profile--basic">
            <ul>
              <li class="grey--text">Programs:</li>
              <li v-for="(row, index) in programs" :key="index">
                •
                <v-icon
                  color="green lighten-2"
                  v-if="row.user_program.is_default"
                >
                  {{ icons.check }}
                </v-icon>
                <span v-html="row.name" />
                <span class="text-italize">
                  ({{ row.user_program.starts_at | formatedDate('ll') }}
                  to
                  {{ row.user_program.valid_until | formatedDate('ll') }})
                </span>
              </li>
            </ul>
          </div>

          <div class="user-profile--basic">
            <v-row>
              <v-col>
                <ul>
                  <li class="grey--text">Membership:</li>
                  <li v-if="user && user.default_subscription">
                    {{ user.default_subscription.name }}
                  </li>
                </ul>
              </v-col>
              <v-col>
                <ul>
                  <li class="grey--text">Membership&nbsp;Status:</li>
                  <span
                    v-if="user && user.pause_status && user.pause_status.active"
                  >
                    <li class="red--text font-weight-bold">PAUSED</li>
                    <li>
                      Start Date:&nbsp;{{
                        user.pause_status.start_date | formatedDate('ll')
                      }}
                    </li>
                    <li>
                      End Date:&nbsp;{{
                        user.pause_status.end_date | formatedDate('ll')
                      }}
                    </li>
                    <li>
                      Justification:&nbsp;{{ user.pause_status.justification }}
                    </li>
                  </span>
                  <li v-else class="green--text">
                    Active
                  </li>
                </ul>
              </v-col>
            </v-row>
          </div>
          <div class="user-profile--basic"></div>
          <div class="user-profile--basic">
            <v-row>
              <v-col>
                <ul>
                  <li class="grey--text">Age:</li>
                  <li v-if="user">
                    {{ user.age }} years old <edit-user-age-dialog />
                  </li>
                </ul>
              </v-col>
              <v-col>
                <ul>
                  <li class="grey--text">Pregnancy-Safe:</li>
                  <li v-if="user && user.main_profile">
                    {{ user.main_profile.pregnancy_type | pregnancyLabels }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row>
      <v-col cols="12" sm="12">
        <!-- <div> -->
        <div>
          <v-row>
            <v-col>
              <div class="d-flex justify-space-between align-center flex-wrap">
                <h1 class="headline primary--text">
                  Main Profile Data

                  <span v-if="user.main_profile && hasProgramms">
                    <edit-user-profile-dialog
                      :profile="user.main_profile"
                      :update-profile-api="updateUserMainProfile"
                    />
                  </span>
                </h1>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="user.main_profile && hasProgramms">
            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Current Goal
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.main_profile.target_goal | readableEnums(targetGoals) }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Body type
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.main_profile.body_type | readableEnums(bodyTypes) }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Physical Activity
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{
                  user.main_profile.physical_activity
                    | readableEnums(physicalActivities)
                }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Workout Frequencies
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{
                  user.main_profile.workout_frequency
                    | readableEnums(workoutFrequencies)
                }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Macro Split
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.main_profile.macro_split | readableEnums(bodyTypes) }}
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Restrictions
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{
                  user.main_profile.restrictions | readableEnums(restrictions)
                }}
              </p>
            </v-col>
          </v-row>

          <v-row class="mt-10" v-if="user.main_profile">
            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Height
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.main_profile.height }} cm
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Weight
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.main_profile.weight }} kg
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Chest
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.main_profile.chest }} cm
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Waist
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.main_profile.waist }} cm
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Hips
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.main_profile.hip }} cm
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Arms
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.main_profile.arms }} cm
              </p>
            </v-col>

            <v-col cols="12" sm="3">
              <h1 class="subtitle-2 grey--text">
                Legs
              </h1>

              <p class="body-1 primary--text font-weight-bold">
                {{ user.main_profile.legs }} cm
              </p>
            </v-col>
          </v-row>

          <no-list
            v-if="!user.main_profile && !hasProgramms"
            details="No Questionnaire Data"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

import NoList from '@/components/elements/NoList'
import EditEmailDialog from '../components/EditEmailDialog'
import EditUserAgeDialog from '../components/EditUserAgeDialog'
import EditUserProfileDialog from '../components/EditUserProfileDialog'
import { mdiCheckCircle } from '@mdi/js'

export default {
  name: 'UserPersonalInformationTab',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    EditEmailDialog,
    EditUserProfileDialog,
    EditUserAgeDialog,
    NoList,
  },

  data() {
    return {
      icons: {
        check: mdiCheckCircle,
      },
    }
  },

  computed: {
    ...mapState({
      bodyTypes: (state) => state.meals.extra.bodyTypes,
      physicalActivityTypes: (state) =>
        state.users.extra.types.physicalActivities,
      restrictions: (state) => state.users.extra.types.restrictions,
      targetGoals: (state) => state.users.extra.types.targetGoals,
      workoutFrequencies: (state) => state.users.extra.types.workoutFrequencies,
      physicalActivities: (state) => state.users.extra.types.physicalActivities,

      user: (state) => state.users.selectedUser,
      programs: (state) => state.users.allUserPrograms,
      selectedProgram: (state) => state.users.selectedUserProgram,
    }),

    hasProgramms() {
      return _.size(this.programs) > 0
    },
  },

  methods: {
    ...mapActions({
      updateUserMainProfile: 'users/updateUserMainProfile',
    }),
  },
}
</script>

<style lang="scss">
.user-profile {
  &--basic {
    width: 50%;
    padding: 0 10px 0 10px;

    &:last-child {
      width: auto;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      color: var(--v-grey-base) !important;

      &:not(:first-child) {
        color: var(--v-primary-base) !important;
        font-weight: 600 !important;
      }
    }
  }

  .questionnaire {
    &--item {
      width: 25%;
    }

    &--nodata {
      height: 200px;
    }
  }
}
</style>
