<template>
  <span>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="secondary"
          v-bind="attrs"
          v-on="on"
          @click="editEmail"
        >
          <v-icon>{{ icon.editEmail }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Update Email
        </v-card-title>

        <v-card-text class="pt-2">
          <v-text-field
            v-model="form.email"
            label="Email"
            autocomplete="off"
            required
            :error-messages="form.$getError('email')"
          >
          </v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="secondary" text @click="dialog = false"> Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :loading="form.$busy" @click="submit">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toast ref="toast" />
  </span>
</template>

<script>
import VToast from '@/components/elements/Toast'
import { mapActions, mapState } from 'vuex'
import { mdiEmailEditOutline } from '@mdi/js'
import Form from '@/utils/form'

export default {
  name: 'UserEditEmailDialog',

  props: {},

  components: {
    VToast,
  },

  data() {
    return {
      icon: {
        editEmail: mdiEmailEditOutline,
      },
      dialog: false,

      form: new Form({
        id: null,
        email: null,
      }),
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.users.selectedUser,
    }),
  },

  methods: {
    ...mapActions({
      updateUser: 'users/updateUser',
    }),

    editEmail() {
      this.form = new Form({ id: this.user.id, email: this.user.email })
      this.form.$busy = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateUser(this.form.$data())
        .then((res) => {
          this.form.$timeout(() => {
            this.user.email = this.form.email
            this.form.$busy = false
            this.dialog = false

            this.$refs.toast.open({
              color: 'primary',
              message: 'Emaill successfully updated.',
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
        })
    },
  },
}
</script>
